import React from 'react';
import PropTypes from 'prop-types';
// import BlockUi from '@availity/block-ui';

import CustomDialog from '../../CustomDialog/CustomDialog';
import { RenderButton } from '../../FormControl';
import { noop, formatTime } from '../../../../utils';
import { SpinnerLoader } from '../../Loader';

const WorkingHoursDialog = ({
  isLoading,
  workingHoursModal,
  setWorkingHoursDialog,
  workingHoursInfo,
  handleInvoiceSubmit,
}) => (
  <CustomDialog
    isOpen={workingHoursModal}
    toggle={() => setWorkingHoursDialog(false)}
    title="Logs"
    classNames="working_hours_dialog"
  >

      <div className="working_hours_table">
        <div className="table_header">
          <p>Date</p>
          <p>Hours</p>
          <p>Ignored Hours</p>
        </div>
        {workingHoursInfo.map((hourItem) => {
          const flatHourItem = Object.entries(hourItem).flat();
          const date = flatHourItem[0];
          const fullHour = flatHourItem[1];
          const splitHour = fullHour.split('.');
          const totalHour = formatTime(splitHour[0]);
          const ignoredHour = formatTime(splitHour[1]);
          return (
            <div className="table_item" key={`${date}-${totalHour}-${ignoredHour}`}>
              <p>{date}</p>
              <p>{totalHour}</p>
              <p>{ignoredHour}</p>
            </div>
          );
        })}
      </div>
      <div className="invoice_submit" style={{position: 'relative'}}>
        {isLoading && workingHoursInfo.length == 0 && <div className='spinning'><SpinnerLoader /></div>}
        <RenderButton type="submit" onClick={handleInvoiceSubmit}>
          Submit Uninvoiced Hours
        </RenderButton>
      </div>
  </CustomDialog>
);

WorkingHoursDialog.propTypes = {
  workingHoursModal: PropTypes.bool,
  isLoading: PropTypes.bool,
  setWorkingHoursDialog: PropTypes.func,
  handleInvoiceSubmit: PropTypes.func,
  workingHoursInfo: PropTypes.instanceOf(Array),
};

WorkingHoursDialog.defaultProps = {
  workingHoursModal: false,
  isLoading: false,
  setWorkingHoursDialog: noop,
  handleInvoiceSubmit: noop,
  workingHoursInfo: [],
};

export default WorkingHoursDialog;
